import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SegmentItem from "./SegmentItem";
import { fetchSegments } from "api/segment";
import { Card } from "antd";
import { useI18n } from "components/I18nProvider";
const SegmentsCard = () => {
    const { intl } = useI18n();
    const { eventId } = useParams();
    const [segments, setSegments] = useState('loading');
    useEffect(() => {
        const fetchSegmentsState = async () => {
            if (eventId === undefined) {
                return;
            }
            const segments = await fetchSegments(eventId);
            setSegments(segments);
        };
        fetchSegmentsState();
    }, []);
    if (segments === 'loading') {
        return _jsx("p", { children: intl.formatMessage({ id: 'loading' }) });
    }
    return (_jsx(Card, { children: segments.map((segment) => _jsx(SegmentItem, { segment: segment }, segment.id)) }));
};
export default SegmentsCard;
