import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { fetchConfig } from "api/config";
import { useEventContext } from "../../event-context";
import NewSegmentSection from "./NewSegmentSection";
import { fetchTestRecordings } from "api/testing";
import { fetchEvent } from "api/events";
import { App, Button } from "antd";
import { forceStopSegment } from "api/segment";
import styles from './style.module.scss';
import { useIntl } from "react-intl";
const NewSegmentSubpage = () => {
    const { message } = App.useApp();
    const intl = useIntl();
    const { event, recording, setEvent } = useEventContext();
    const [config, setConfig] = useState('loading');
    const [audioInputDevices, setAudioInputDevices] = useState('loading');
    const [testRecordings, setTestRecordings] = useState('loading');
    useEffect(() => {
        const fetchConfigState = async () => {
            setConfig(await fetchConfig());
        };
        const getMicrophoneConsent = async () => {
            try {
                await navigator.mediaDevices.getUserMedia({ audio: true });
                return 'granted';
            }
            catch (error) {
                void message.error('Microphone access denied');
                return 'denied';
            }
        };
        const readAudioInputDevices = async () => {
            const micConsent = await getMicrophoneConsent();
            if (micConsent === 'denied') {
                setAudioInputDevices('denied');
                return;
            }
            const devices = await navigator.mediaDevices.enumerateDevices();
            const audioDevices = devices.filter((device) => device.kind === 'audioinput');
            if (audioDevices.length === 0) {
                void message.error('No microphone found. Try again');
                return;
            }
            setAudioInputDevices(audioDevices);
        };
        const loadTestRecordings = async () => {
            const recordings = await fetchTestRecordings();
            setTestRecordings(recordings);
        };
        loadTestRecordings();
        readAudioInputDevices();
        fetchConfigState();
    }, []);
    if (config === 'loading' || audioInputDevices === 'loading' || testRecordings === 'loading') {
        return _jsx("p", { children: intl.formatMessage({ id: 'loading' }) });
    }
    const handleSegmentEnded = async () => {
        const updatedEvent = await fetchEvent(event.id);
        if (updatedEvent === 'not-found') {
            return;
        }
        setEvent(updatedEvent);
    };
    const handleEndSegment = async () => {
        await forceStopSegment(event.id, event.activeSegment.id);
        handleSegmentEnded();
    };
    const { activeSegment } = event;
    if (activeSegment !== null) {
        return (_jsxs("div", { className: styles.alert, children: [_jsxs("p", { children: ["The segment ", _jsx("strong", { children: activeSegment.title }), " is still in progress!"] }), _jsx("p", { children: "Are you recording in another tab or device? You cannot start a new segment until the current one is ended." }), _jsx("div", { className: styles.alertControls, children: _jsx(Button, { onClick: handleEndSegment, danger: true, children: "Force stop segment" }) })] }));
    }
    return (_jsx(NewSegmentSection, { event: event, config: config, recording: recording, audioInputDevices: audioInputDevices, testRecordings: testRecordings, onSegmentEnded: handleSegmentEnded }));
};
export default NewSegmentSubpage;
