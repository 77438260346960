export class IdleTimer {
    constructor() {
        this.idleCallback = () => { };
        this.activeCallback = () => { };
        this.timeout = null;
        this.handleMouseMove = () => {
            this.clearTimer();
            this.activeCallback();
            this.timeout = window.setTimeout(this.idleCallback, 3000);
        };
    }
    mount() {
        window.addEventListener('mousemove', this.handleMouseMove);
    }
    onIdle(callback) {
        this.idleCallback = callback;
    }
    onActive(callback) {
        this.activeCallback = callback;
    }
    unmount() {
        this.clearTimer();
        window.removeEventListener('mousemove', this.handleMouseMove);
    }
    clearTimer() {
        if (this.timeout !== null) {
            clearTimeout(this.timeout);
        }
    }
}
