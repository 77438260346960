import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Alert, Button, Card, Form, Select } from 'antd';
import { AudioMutedOutlined, AudioOutlined } from '@ant-design/icons';
import { useI18n } from 'components/I18nProvider';
import MicFeedback from '../MicFeedback';
import styles from './style.module.scss';
const searchParams = new URLSearchParams(window.location.search);
const RecordingPanel = ({ form, audioInputDevices, testRecordings, recording, }) => {
    const { intl } = useI18n();
    const deviceId = Form.useWatch('deviceId', form);
    const [translationEnabled, setTranslationEnabled] = useState(false);
    useEffect(() => {
        if (recording.phase !== 'idle') {
            return;
        }
        if (deviceId !== null && deviceId !== undefined) {
            recording.runFeedback(deviceId);
        }
    }, [deviceId]);
    const handleMuteRecording = () => {
        if (recording.phase !== 'in-progress') {
            return;
        }
        recording.mute(recording.muted ? 'off' : 'on');
    };
    if (audioInputDevices === 'denied') {
        return (_jsx(Alert, { message: intl.formatMessage({ id: 'noMicPermission' }), showIcon: true, type: "error" }));
    }
    return (_jsx(Card, { children: _jsxs("div", { className: styles.recordingPanelContent, children: [recording.phase === 'in-progress' && recording.muted ? (_jsx(AudioMutedOutlined, { className: styles.micIcon })) : (_jsx(AudioOutlined, { className: styles.micIcon })), _jsxs("div", { className: styles.inputs, children: [!searchParams.has('testing') && (_jsx(Form.Item, { className: styles.inputFormItem, name: "deviceId", label: intl.formatMessage({ id: 'input' }), rules: [{ required: true, message: intl.formatMessage({ id: 'inputDeviceRequired' }) }], required: false, children: _jsx(Select, { options: audioInputDevices.map((device) => ({ label: device.label, value: device.deviceId })), disabled: recording.phase !== 'idle', placeholder: intl.formatMessage({ id: 'selectInput' }) }) })), searchParams.has('testing') && (_jsx(Form.Item, { className: styles.inputFormItem, name: "testRecordingId", label: intl.formatMessage({ id: 'testRecording' }), children: _jsx(Select, { options: testRecordings.map((recording) => ({ label: recording.title, value: recording.id })), disabled: recording.phase !== 'idle' }) })), _jsxs("div", { className: styles.feedback, children: [_jsx(MicFeedback, { analyser: recording.phase === 'idle' || recording.phase === 'in-progress'
                                        ? recording.audioAnalyser
                                        : null }), _jsx("div", { className: styles.micControls, children: recording.phase === 'in-progress' && (_jsx(Button, { onClick: handleMuteRecording, children: intl.formatMessage({ id: recording.phase === 'in-progress' && recording.muted ? 'unmute' : 'mute' }) })) })] })] }), _jsx("div", { className: styles.controls, children: recording.phase === 'in-progress' ? (_jsx(Button, { size: 'large', className: styles.controlsButton, danger: true, htmlType: "submit", children: intl.formatMessage({ id: 'endSegment' }) })) : (_jsx(Button, { size: 'large', disabled: recording.phase !== 'idle', className: styles.controlsButton, loading: recording.phase === 'starting', type: "primary", htmlType: "submit", children: intl.formatMessage({ id: 'startRecording' }) })) })] }) }));
};
export default RecordingPanel;
