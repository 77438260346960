import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal, Form, Input } from 'antd';
import { createEvent } from 'api/events';
import { useI18n } from 'components/I18nProvider';
import { useState } from 'react';
const { TextArea } = Input;
const NewEventDialog = ({ open, onNewEvent, onClose }) => {
    const { intl } = useI18n();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const handleOk = () => {
        form.submit();
    };
    const handleFinish = async (values) => {
        setLoading(true);
        const event = await createEvent(values.title, values.description ?? '');
        onNewEvent(event);
        setLoading(false);
    };
    return (_jsx(Modal, { title: intl.formatMessage({ id: 'newEvent' }), open: open, onOk: handleOk, onCancel: onClose, confirmLoading: loading, okButtonProps: { disabled: loading }, getContainer: false, children: _jsxs(Form, { form: form, initialValues: { slug: '', title: '' }, onFinish: handleFinish, disabled: loading, children: [_jsx(Form.Item, { label: intl.formatMessage({ id: 'title' }), name: "title", rules: [{ required: true, message: 'Please input title!' }], children: _jsx(Input, { autoFocus: true }) }), _jsx(Form.Item, { label: intl.formatMessage({ id: 'description' }), name: "description", children: _jsx(TextArea, { rows: 3 }) })] }) }));
};
export default NewEventDialog;
