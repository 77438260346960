import { jsx as _jsx } from "react/jsx-runtime";
import { Card } from 'antd';
import styles from './style.module.scss';
const TwoColumnPanel = ({ title, children }) => {
    return (_jsx(Card, { title: title, children: _jsx("div", { className: styles.panelContent, children: children }) }));
};
const Column = ({ children }) => {
    return _jsx("div", { className: styles.column, children: children });
};
TwoColumnPanel.Column = Column;
export default TwoColumnPanel;
