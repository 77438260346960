import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useI18n } from "components/I18nProvider";
import styles from './style.module.scss';
import { CheckCircleOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
const searchParams = new URLSearchParams(window.location.search);
const SegmentItem = ({ segment }) => {
    const { intl } = useI18n();
    return (_jsxs("div", { className: styles.segmentItem, children: [_jsxs("div", { children: [_jsx("h3", { children: segment.title }), _jsx("p", { children: segment.description }), searchParams.has('testing') && (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.transcriptions, children: [_jsxs("span", { children: [intl.formatMessage({ id: 'original' }), ":"] }), _jsx("a", { href: `/apps/live/spa/events/${segment.eventId}/segments/${segment.id}/transcriptions/${segment.audioLanguage}`, children: intl.formatMessage({ id: segment.audioLanguage }) })] }), segment.translation !== 'off' && (_jsxs("div", { className: styles.transcriptions, children: [_jsxs("span", { children: [intl.formatMessage({ id: 'translated' }), ":"] }), segment.translation.languages.map((lang) => (_jsx("a", { href: `/apps/live/spa/events/${segment.eventId}/segments/${segment.id}/transcriptions/${lang}`, children: intl.formatMessage({ id: lang }) })))] }))] }))] }), segment.status === 'recording' && _jsx(Loading3QuartersOutlined, { className: styles.icon, spin: true }), segment.status === 'completed' && _jsx(CheckCircleOutlined, { className: styles.icon })] }));
};
export default SegmentItem;
