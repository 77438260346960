import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useI18n } from 'components/I18nProvider';
import { Spin } from 'antd';
import Page from 'components/Page';
import { fetchTranscription } from 'api/segment';
const TranscriptionPage = () => {
    const { eventId, segmentId, lang } = useParams();
    const { intl } = useI18n();
    const [transcription, setTranscription] = useState('loading');
    useEffect(() => {
        const fetchTranscriptionState = async () => {
            if (eventId === undefined || segmentId === undefined || lang === undefined) {
                return;
            }
            const transcription = await fetchTranscription(eventId, segmentId, lang);
            if (transcription === null) {
                setTranscription('not-found');
                return;
            }
            else {
                setTranscription(transcription);
            }
        };
        fetchTranscriptionState();
    }, [eventId, segmentId, lang]);
    return (_jsxs(Page, { children: [_jsxs("h1", { children: [intl.formatMessage({ id: 'transcription' }), ' - ', intl.formatMessage({ id: lang })] }), _jsxs("div", { children: [transcription === 'loading' && _jsx(Spin, {}), transcription === 'not-found' ? (_jsx("p", { children: intl.formatMessage({ id: 'transcriptionNotFound' }) })) : (_jsx("p", { children: transcription }))] })] }));
};
export default TranscriptionPage;
