export const fontSizes = {
    sm: 0.6,
    md: 0.8,
    lg: 1,
    xl: 1.3,
};
export const isFontSize = (value) => {
    return value in fontSizes;
};
const isSubtitlesTheme = (value) => {
    return value === 'dark' || value === 'light';
};
export const loadSubtitlesSettings = (customValues = {}) => {
    const storedTheme = localStorage.getItem('subtitlesTheme');
    const storedFontSize = localStorage.getItem('subtitlesFontSize');
    return {
        theme: isSubtitlesTheme(storedTheme) ? storedTheme : 'dark',
        fullscreen: false,
        fontSize: isFontSize(storedFontSize) ? storedFontSize : 'lg',
        ...customValues,
    };
};
export const storeSubtitlesSettings = (settings) => {
    localStorage.setItem('subtitlesTheme', settings.theme);
    localStorage.setItem('subtitlesFontSize', settings.fontSize);
};
