import { BASE_PATH } from "lib/resolveUrl";
export const apiUrl = (relativePath) => {
    return BASE_PATH + relativePath;
};
export const authFetch = (relativePath, options = {}) => {
    const suppliedHeaders = options.headers ?? {};
    const headers = {
        ...suppliedHeaders,
        'Authorization': window.localStorage.authString,
    };
    return fetch(apiUrl(relativePath), {
        ...options,
        headers,
    });
};
