import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import { useI18n } from 'components/I18nProvider';
import { sortLocaleOptions } from 'lib/locales';
import TwoColumnPanel from '../TwoColumnPanel';
const localeFilter = (input, option) => (option !== undefined && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0);
const Column = TwoColumnPanel.Column;
const SegmentPanel = ({ form, engines, disabled = false }) => {
    const { intl } = useI18n();
    const selectedEngine = Form.useWatch((values) => engines.find((engine) => engine.name === values.engine), form) ?? engines[0];
    useEffect(() => {
        form.setFieldValue('inputLanguage', selectedEngine.languages[0]);
    }, [selectedEngine]);
    const prepareLocaleOptions = (locales) => sortLocaleOptions(locales.map((lang) => ({ label: intl.formatMessage({ id: lang }), value: lang })));
    return (_jsxs(TwoColumnPanel, { title: intl.formatMessage({ id: 'newSegment' }), children: [_jsxs(Column, { children: [_jsx(Form.Item, { name: "title", label: intl.formatMessage({ id: 'title' }), children: _jsx(Input, { disabled: disabled }) }), _jsx(Form.Item, { name: "description", label: intl.formatMessage({ id: 'description' }), children: _jsx(Input.TextArea, { disabled: disabled }) })] }), _jsxs(Column, { children: [_jsx(Form.Item, { name: "engine", label: intl.formatMessage({ id: 'engine' }), children: _jsx(Select, { style: { width: '100%' }, placeholder: "Please select", options: engines.map((engine) => ({ label: engine.name, value: engine.name })), disabled: disabled }) }), _jsx(Form.Item, { name: "inputLanguage", label: intl.formatMessage({ id: 'audioLanguage' }), children: _jsx(Select, { style: { width: '100%' }, placeholder: "Please select", filterOption: localeFilter, options: prepareLocaleOptions(selectedEngine.languages), disabled: disabled }) })] })] }));
};
export default SegmentPanel;
