import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import styles from "./style.module.scss";
const drawBackgroundMarks = (ctx, canvasWidth, canvasHeight) => {
    const stepsCount = 16;
    const gap = 2;
    const totalGap = (stepsCount - 1) * gap;
    const stepWidth = (canvasWidth - totalGap) / stepsCount;
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);
    for (let i = 0; i < stepsCount; i++) {
        const x = i * (stepWidth + 2);
        ctx.fillStyle = '#dfdfdf';
        ctx.fillRect(x, 0, stepWidth, canvasHeight);
    }
};
const computeNormalisedDecibels = (dataArray) => {
    let squaresSum = 0;
    for (let i = 0; i < dataArray.length; i++) {
        const centered = dataArray[i] - 128;
        squaresSum += centered * centered;
    }
    const rootMeanSquare = Math.sqrt(squaresSum / dataArray.length);
    const decibels = 20 * Math.log10(rootMeanSquare / 128);
    const minDb = -50;
    const maxDb = 0;
    const clamped = Math.max(minDb, Math.min(decibels, maxDb));
    const normalized = (clamped - minDb) / (maxDb - minDb);
    return normalized;
};
const MicFeedback = ({ analyser }) => {
    const canvasRef = useRef(null);
    useEffect(() => {
        if (analyser === null) {
            const canvas = canvasRef.current;
            if (canvas === null) {
                return;
            }
            const ctx = canvas.getContext('2d');
            if (ctx === null) {
                return;
            }
            drawBackgroundMarks(ctx, canvas.width, canvas.height);
            return;
        }
        const dataArray = new Uint8Array(analyser.fftSize);
        let isRunning = true;
        const visualise = () => {
            const canvas = canvasRef.current;
            if (canvas === null) {
                return;
            }
            const ctx = canvas.getContext('2d');
            if (ctx === null) {
                return;
            }
            if (!isRunning) {
                drawBackgroundMarks(ctx, canvas.width, canvas.height);
                return;
            }
            requestAnimationFrame(visualise);
            analyser.getByteTimeDomainData(dataArray);
            const decibels = computeNormalisedDecibels(dataArray);
            const barWidth = decibels * canvas.width;
            drawBackgroundMarks(ctx, canvas.width, canvas.height);
            ctx.fillStyle = '#ffae43';
            ctx.fillRect(0, 0, barWidth, canvas.height);
        };
        visualise();
        return () => {
            isRunning = false;
        };
    }, [analyser]);
    return (_jsx("canvas", { ref: canvasRef, className: styles.feedbackCanvas }));
};
export default MicFeedback;
