import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from "react";
import czech from '../../translations/cs.json';
import english from '../../translations/en.json';
import { IntlContext, IntlProvider } from "react-intl";
const messages = {
    en: english,
    cs: czech
};
const getBrowserLanguage = () => {
    const abbrev = navigator.language.split('-')[0];
    if (abbrev in messages) {
        return abbrev;
    }
    return 'en';
};
const getUserLocale = () => {
    const locale = localStorage.getItem('locale');
    if (locale === null) {
        return getBrowserLanguage();
    }
    if (locale in messages) {
        return locale;
    }
    return 'en';
};
export const I18nContext = createContext(undefined);
export const useI18n = () => useContext(I18nContext);
export const I18nProvider = ({ children }) => {
    const [locale, setLocale] = useState(getUserLocale);
    const handleChangeLocale = (locale) => {
        localStorage.setItem('locale', locale);
        setLocale(locale);
    };
    return (_jsx(IntlProvider, { messages: messages[locale], defaultLocale: 'en', locale: locale, children: _jsx(IntlContext.Consumer, { children: (intl) => (_jsx(I18nContext.Provider, { value: { intl, changeLocale: handleChangeLocale }, children: children })) }) }));
};
