import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from "clsx";
import { Popover, Select, Space } from "antd";
import { resolvePath } from "lib/resolveUrl";
import { SettingOutlined } from "@ant-design/icons";
import { useI18n } from "components/I18nProvider";
import styles from './style.module.scss';
import { Link } from "react-router-dom";
const PageHeader = ({}) => {
    const { intl, changeLocale } = useI18n();
    const menuContent = (_jsxs(Space, { children: [`${intl.formatMessage({ id: 'language' })}:`, _jsx(Select, { onChange: changeLocale, value: intl.locale, size: "small", options: [
                    {
                        value: 'en',
                        label: intl.formatMessage({ id: 'en-US' })
                    },
                    {
                        value: 'cs',
                        label: intl.formatMessage({ id: 'cs-CZ' })
                    },
                ] })] }));
    return (_jsx("div", { className: styles.header, children: _jsxs("div", { className: clsx('container', styles.headerContainer), children: [_jsxs(Link, { to: "/apps/live", className: styles.brand, children: [_jsx("img", { className: styles.logo, src: resolvePath('/static/img/app-logo.svg'), alt: "Logo" }), _jsx("div", { className: styles.appName, children: "BeeyLive" })] }), _jsx("div", { className: styles.toolbar, children: _jsx(Popover, { trigger: ['click'], content: menuContent, overlayClassName: styles.popover, arrow: false, placement: "bottomRight", children: _jsx(SettingOutlined, { className: styles.settings }) }) })] }) }));
};
export default PageHeader;
